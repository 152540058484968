<template>
  <div class="onboarding-page__wrapper">
    <div class="complete-page">
      <h2 class="title">
        빅크리에이터 신청이 <br class="mo" />완료되었습니다 :)
      </h2>

      <p class="sub-text-s2 text-gray-second">
        빅크리에이터로 신청해주셔서 진심으로 감사드립니다. <br />심사팀에서
        영업일 기준 5일 이내로 승인 여부를 별도로 연락드리겠습니다. <br /><br />
        승인 후에는 심사팀 전담 매니저를 통해<br />
        스튜디오 사용 등 상세히 안내해 드릴 예정이니 조금만 기다려주세요.
        <br /><br />
        빅크가 크리에이터와 팬들이 즐겁게 활동할 수 있는 플랫폼이 되고자
        최소한의 검증 과정을 두는 점, 양해 부탁드립니다.
      </p>

      <card-basic class="guide-card">
        <p class="title sub-text-s2">
          빅크 스튜디오 사용에 있어 최소한의 기준은 아래와 같습니다
        </p>
        <ul class="b-text-2 text-gray-second">
          <li>
            등록하신 SNS 또는 커뮤니티 및 본인 페이지 등에 창작자 본인 고유의
            콘텐츠로 활동하는지 여부
          </li>
          <li>
            기존 활동 중이신 채널에서의 실제 본인의 활동 등 계정의 활성화 여부
          </li>
          <li>
            기존 활동 내역에서 콘텐츠 위배사항 발견 시 제외(스캠, 어뷰징, 성적
            이슈 계정 차단)
          </li>
        </ul>

        <button-basic
          class="policy-btn"
          text="빅크 콘텐츠 운영 정책 자세히 보기"
          text-size="s3"
          bg-color="#ffffff"
          color="#0D0D10"
          padding="8px 0"
          border-color="#E6EAEF"
          :border="true"
          @action="actions.goToPolicy()"
        ></button-basic>
      </card-basic>
    </div>
  </div>
</template>

<script>
import CardBasic from "../../../../components/console/cards/CardBasic";
import ButtonBasic from "../../../../components/console/buttons/ButtonBasic";
import helper from "@/helper";

export default {
  name: "OnBoardingComplete",
  components: { ButtonBasic, CardBasic },
  setup() {
    const actions = {
      goToPolicy: () => {
        helper.goToExternalUrl(
          "https://bigc-im.notion.site/2fd1b01e888c4bcab5d4f133a026da83"
        );
      },
    };

    return { actions };
  },
};
</script>

<style src="./style.css" scoped></style>
